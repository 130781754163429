import { ReactNode, ReactElement } from 'react';

import { Box, Typography } from '@material-ui/core';
import clsx from 'classnames';

import useStyles from './Layout.style';

import { LOGO_PATH_MUNDARO_LARGE } from 'common';
import { Appbar, Sidebar } from 'components';
import { useAppSelector } from 'hooks';

/**
 * The property types which are used by the `Layout` container
 */
export interface LayoutProps {
  children: ReactNode;
  fullScreen: boolean;
}

/**
 * A container that handles the layout of the application
 *
 * @param children The children to display within the container
 * @param fullScreen If the site should be full screen
 *
 * @returns The `Layout` container
 */
export function Layout({ children, fullScreen }: LayoutProps): ReactElement {
  const styles = useStyles();
  const { sidebarOpen } = useAppSelector((state) => state.app);

  return (
    <div style={{ overflowX: 'hidden' }}>
      {fullScreen ? (
        <Box minHeight="100vh">{children}</Box>
      ) : (
        <>
          <Appbar />
          <Sidebar />
          <main
            className={clsx(styles.content, {
              [styles.contentSidebarOpen]: sidebarOpen,
              [styles.contentSidebarClosed]: !sidebarOpen,
            })}
          >
            <Box className={styles.children}>{children}</Box>
            <Box className={styles.copyright}>
              <p>
                Copyright &copy; {new Date().getFullYear()} NoorderNieuws.
                Alle rechten voorbehouden.
              </p>
              <a
                href="https://mundaro.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={LOGO_PATH_MUNDARO_LARGE}
                  alt="NoorderNieuws Logo"
                  className={styles.logo}
                />
              </a>
            </Box>
            <Box textAlign="center" className={styles.copyright}>
              <Typography variant="overline">
                Bevat deze pagina een bug? Klik{' '}
                <a
                  href={`mailto:info@mundaro.nl?subject=Fout%20in%20PB-Heitink.nl%20&body=Beschrijf de bug:\n`}
                >
                  hier
                </a>{' '}
                om het te melden.
              </Typography>
            </Box>
          </main>
        </>
      )}
    </div>
  );
}
