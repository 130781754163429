import { ReactElement, useState } from 'react';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { MenuOpen as MenuIcon } from '@material-ui/icons';
import clsx from 'classnames';
import { NavLink } from 'react-router-dom';

import useStyles from './Appbar.style';

import { LOGO_PATH_LARGE, LOGO_PATH_SMALL } from 'common';
import { AccountMenu } from 'components/AccountMenu/AccountMenu';
import { closeSidebar, openSidebar } from 'features';
import { useAppDispatch, useAppSelector } from 'hooks';

/**
 * A component that shows an application bar at the top of the screen
 *
 * @returns The `Appbar` component
 */
export function Appbar(): ReactElement {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { sidebarOpen } = useAppSelector((state) => state.app);
  const { session } = useAppSelector((state) => state.auth);

  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);

  /**
   * If the screen is small
   */
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  /**
   * The id used by the account menu
   */
  const accountMenuId = 'user-account-menu';

  /**
   * A function that handles the opening of the account menu
   * @param event The event to use
   */
  const handleAccountMenuOpen = (event: any) => {
    setAccountMenuAnchor(event.currentTarget);
  };

  /**
   * A function that handles the closing of the account menu
   */
  const handleAccountMenuClose = () => {
    setAccountMenuAnchor(null);
  };

  /**
   * A function that handles the opening of the sidebar
   */
  const handleSidebarOpen = () => dispatch(openSidebar());

  /**
   * A function that handles the closing of the sidebar
   */
  const handleSidebarClose = () => dispatch(closeSidebar());

  return (
    <>
      <AccountMenu
        id={accountMenuId}
        anchor={accountMenuAnchor}
        onClose={handleAccountMenuClose}
      />
      <AppBar position="fixed" className={styles.root} color="secondary">
        <Toolbar className={styles.toolbar}>
          <IconButton
            className={clsx({
              [styles.menuIconOpen]: sidebarOpen,
              [styles.menuIconClosed]: !sidebarOpen,
            })}
            onClick={sidebarOpen ? handleSidebarClose : handleSidebarOpen}
          >
            <MenuIcon color="primary" />
          </IconButton>
          <img
            src={isSmall ? LOGO_PATH_SMALL : LOGO_PATH_LARGE}
            alt="NoorderNieuws Logo"
            className={styles.logo}
          />
          <Box flexGrow={1} />
          {session?.user ? (
            <>
              {!isSmall && (
                <Typography variant="h6" className={styles.name}>
                  {session.user.name}
                </Typography>
              )}
              <Avatar
                onClick={handleAccountMenuOpen}
                src={session.user.image}
                className={styles.avatar}
              >
                {session.user.name.charAt(0)}
              </Avatar>
            </>
          ) : (
            <NavLink to="/inloggen" className={styles.link}>
              <Button variant="outlined" color="primary">
                Inloggen
              </Button>
            </NavLink>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
